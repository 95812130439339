<template>
    <section class="references-section" :class="{ opened: open }">
        <amp-row-first>
            <template #input>
                <v-btn
                    text
                    class="justify-start pa-8"
                    block
                    tile
                    color="pimary"
                    :ripple="false"
                    :disabled="disabled"
                    @click="toggleOpen"
                >
                    <v-icon small left class="icon">
                        {{ open ? 'chevron-up' : 'chevron-down' }}
                    </v-icon>
                    <span class="label">
                        {{ label }}
                    </span>
                </v-btn>
            </template>
        </amp-row-first>

        <v-expand-transition>
            <section v-show="expand" class="mt-1">
                <slot></slot>
            </section>
        </v-expand-transition>

        <amp-row-last v-if="expand" />
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import { Watch } from '@/utils/decorators';
import Component from 'vue-class-component';

import { AmpRowFirst, AmpRowLast } from '@/components/AmpModule/AmpPage';

const AmpExpansionPanelProps = Vue.extend({
    props: {
        expand: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        label: {
            type: String,
            default() {
                return 'Click to Expand';
            }
        }
    }
});

@Component({
    components: {
        AmpRowFirst,
        AmpRowLast
    }
})
export default class AmpExpansionPanel extends AmpExpansionPanelProps {
    open = true;

    @Watch('expand')
    onExpandChange(expand: boolean) {
        this.open = expand;
    }

    toggleOpen() {
        const newState = !this.expand;
        this.$emit('update:expand', newState); // Emit change to parent
    }

    mounted() {
        this.open = this.expand;
    }
}
</script>

<style lang="scss" scoped>
.references-section::v-deep {
    margin-bottom: 1em;

    &.opened {
        .icon,
        .label {
            color: $primary-color !important;
        }
    }

    .elevated-input-first {
        padding: 0 !important;
        border-top: 6px solid $faded-blue !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
</style>
