<template>
    <div>
        <v-divider class="mb-6" />
        <label class="d-block font-weight-bold">
            Date
            <span v-if="!hasPublishTime" class="font-weight-light ml-6">
                Specify the earliest day and time this Amp should go live
            </span>
        </label>
        <div class="date-fields d-inline-flex align-center mt-4">
            <v-menu
                v-model="showDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                :disabled="!canEditPublishTime"
            >
                <template #activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDate"
                        readonly
                        v-bind="attrs"
                        solo
                        flat
                        hide-details
                        class="field__publish-date"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="date"
                    :min="minDate"
                    :max="maxDate"
                    :show-current="true"
                    :disabled="!canEditPublishTime"
                    @input="updateDate"
                />
            </v-menu>
            <v-menu
                v-model="showTimePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
                :disabled="!canEditPublishTime"
            >
                <template #activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedTime"
                        readonly
                        v-bind="attrs"
                        solo
                        flat
                        hide-details
                        class="field__publish-time"
                        v-on="on"
                    />
                </template>
                <v-time-picker
                    v-if="showTimePicker"
                    v-model="time"
                    :full-width="true"
                    :show-current="true"
                    :min="minTime"
                    :disabled="!canEditPublishTime"
                />
            </v-menu>
            {{ timeZone }}
            <v-icon right small>calendar</v-icon>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Announcement } from '@/types/Announcement';
import { formatDate } from '@/utils/helpers';
import { getTimezone } from '@/utils/helpers/formatDate';

const DateSelectorProps = Vue.extend({
    name: 'DateSelector',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    press_release: null,
                    min_distribution_time: 0,
                    has_published_status: false
                };
            }
        }
    }
});

@Component<DateSelector>({
    watch: {
        date() {
            this.$emit('change', {
                date: this.date
            });
        },
        time() {
            this.$emit('change', {
                time: this.time
            });
        }
    }
})
export default class DateSelector extends DateSelectorProps {
    showDatePicker = false;
    showTimePicker = false;

    date = this.$date(
        this.announcement?.min_distribution_time ?? void 0
    ).format('YYYY-MM-DD');
    minDate = this.$date().format('YYYY-MM-DD');
    maxDate = this.$date().add(1, 'year').format('YYYY-MM-DD');

    time = this.$date(
        this.announcement?.min_distribution_time ?? void 0
    ).format('HH:mm');

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get hasPublishTime() {
        return Boolean(this.announcement?.min_distribution_time);
    }

    get canEditPublishTime() {
        return !this.hasPublishTime || !this.announcement.has_published_status;
    }

    get formattedDate() {
        return formatDate(this.date);
    }

    get formattedTime() {
        const [hour, minute] = this.time.split(':');

        if (!hour || !minute) {
            return '';
        }

        return this.$date()
            .set('hour', Number(hour))
            .set('minute', Number(minute))
            .format('LT');
    }

    get timeZone() {
        return this.pressRelease
            ? getTimezone(
                  `${this.date} ${this.time}`,
                  'YYYY-MM-DD HH:mm',
                  this.pressRelease.timezone
              )
            : '';
    }

    get minTime() {
        return this.$date(this.date).format('YYYY-MM-DD') ===
            this.$date().format('YYYY-MM-DD')
            ? this.$date().format('HH:mm')
            : '';
    }

    mounted() {
        this.setDate();
    }

    setDate() {
        if (this.announcement?.min_distribution_time) {
            this.date = this.$date
                .unix(this.announcement.min_distribution_time)
                .subtract(1, 'days')
                .format('YYYY-MM-DD'); // @todo - subtraction needs fix as date saved in db is not same what we sent from here

            this.time = this.$date
                .unix(this.announcement.min_distribution_time)
                .subtract(30, 'minutes')
                .format('HH:mm'); // @todo - subtraction needs fix as date saved in db is not same what we sent from here
        }
    }

    updateDate() {
        this.showDatePicker = false;

        this.adjustTime();
    }

    adjustTime() {
        if (this.minTime) {
            if (
                this.$date(this.time, 'HH:mm').isBefore(
                    this.$date(this.minTime, 'HH:mm')
                )
            ) {
                this.time = this.minTime;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.date-fields::v-deep {
    background-color: $main-background;
    padding-right: 1em;
    border-radius: 4px;

    .field__publish-date {
        width: 8em;
    }

    .field__publish-time {
        width: 6em;
    }

    .v-input__slot {
        background-color: $main-background !important;
    }
}
</style>
